import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import {
  Card,
  ROW_TYPE,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  IconHelp,
  IconInkShipmentMissing,
  IconPersonAssist,
  IconPhone,
  IconReportAlert
} from '@veneer/core/';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  CallSupportHyperLinkClicked,
  GetInstantInkSupportButtonClicked,
  MissingInkButtonClicked,
  MyBillWasNotWhatIExpectedButtonClicked,
  ProLiveSupportButtonClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { getResourceId } from 'src/utils/utils';
import { SubscriptionsCardProps } from '../SubscriptionsCard/types';

export const HaveQuestions = ({ subscription }: SubscriptionsCardProps) => {
  const { translate } = useDependencyManagerContext();
  const resourceId = getResourceId(subscription);
  const rows = [] as CardRowProps[];

  if (subscription?.type == SubscriptionType.INSTANT_INK) {
    rows.push(
      {
        index: 0,
        icon: <IconInkShipmentMissing />,
        type: ROW_TYPE.EXTERNAL,
        content: translate(
          'subscriptions.imMissingInk',
          'I’m missing ink'
        ) as string,
        link: {
          url: URLS.ImMissingInk,
          dataTestId: 'MissingInkLink'
        },
        analyticsEvent: MissingInkButtonClicked(
          resourceId,
          subscription.startDate
        )
      },
      {
        index: 1,
        icon: <IconReportAlert />,
        type: ROW_TYPE.EXTERNAL,
        content: translate(
          'subscriptions.myBillWasNotWhatIExpected',
          `My bill wasn't what I expected`
        ) as string,
        link: {
          url: URLS.MyBillWasNotWhatIExpected,
          dataTestId: 'InstantInkMyBillWasNotWhatIExpectedLink'
        },
        analyticsEvent: MyBillWasNotWhatIExpectedButtonClicked(
          resourceId,
          subscription.startDate,
          SubscriptionType.INSTANT_INK
        )
      },
      {
        index: 2,
        icon: <IconHelp />,
        type: ROW_TYPE.EXTERNAL,
        content: translate(
          'subscriptions.getInstantInkSupport',
          'Get Instant Ink support'
        ) as string,
        link: {
          url: URLS.GetInstantInkSupport,
          dataTestId: 'GetInstantInkSupportLink'
        },
        analyticsEvent: GetInstantInkSupportButtonClicked(
          resourceId,
          subscription.startDate
        )
      }
    );
  } else if (subscription?.type == SubscriptionType.HP_ALL_IN) {
    const phoneNumber = '1-888-447-0148';

    rows.push(
      {
        index: 0,
        icon: <IconPersonAssist size={24} />,
        type: ROW_TYPE.EXTERNAL,
        content: translate(
          'subscriptions.proLiveSupport',
          'Contact 24/7 Pro live support'
        ) as string,
        link: {
          url: URLS.ProLiveSupport,
          dataTestId: 'ProLiveSupport'
        },
        analyticsEvent: ProLiveSupportButtonClicked()
      },
      {
        index: 1,
        icon: <IconPhone size={24} />,
        type: ROW_TYPE.NONE,
        content: translate('subscriptions.callSupport', {
          defaultValue: 'Call support at {{phoneNumber}}',
          replace: { phoneNumber }
        }) as string,
        link: {
          url: `tel:${phoneNumber}`,
          dataTestId: 'CallSupport'
        },
        analyticsEvent: CallSupportHyperLinkClicked(phoneNumber)
      }
    );
  }

  return (
    <Card
      title={{
        content: translate(
          'subscriptions.haveQuestions',
          'Have questions?'
        ) as string
      }}
      rows={rows}
      data-testid="have-questions-section"
    />
  );
};

export default HaveQuestions;
